<template>
  <v-dialog v-model="dialog" max-width="900" persistent transition="dialog-bottom-transition" @click:outside="$emit('close')">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Parâmetros</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn dark text @click="showAddParam = true">
            Adicionar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container v-if="parameters">
        <p class="px-4 pt-2">
          *Para excluir uma variável, clicar no ícone <strong style="color: #F44336">vermelho</strong> a direita da variável a ser excluída.
        </p>
        <div v-for="(value, key) in parameters" :key="key" class="box">
          <v-row class="mx-auto">
            <v-col cols="5">
              <v-text-field readonly label="Parâmetro" :value="key" required hide-details outlined />
            </v-col>
            <v-col cols="7">
              <v-text-field v-model="parameters[key]" readonly label="Valor" required hide-details outlined>
                <template v-slot:append-outer> <v-icon color="error" class="mb-2" @click="removeParam(key)">mdi-delete</v-icon></template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-alert v-if="!parameters || !Object.keys(parameters).length" class="mx-2" outlined type="info" dense>Não há parâmetros configurados</v-alert>
      </v-container>
    </v-card>

    <v-dialog v-if="parameters" v-model="showAddParam" width="700" persistent>
      <v-card class="pa-4">
        <v-card-title class="headline pa-0 pb-2">Adicionar Parâmetro</v-card-title>
        <v-row no-gutters>
          <v-col cols="5">
            <v-text-field v-model="param" class="mr-2" label="Parâmetro" outlined />
          </v-col>
          <v-col cols="7">
            <v-text-field v-model="value" label="Valor" outlined />
          </v-col>
        </v-row>
        <v-card-actions class="pa-0">
          <v-btn color="error" @click="closeAddParam">Cancelar</v-btn>
          <v-btn color="success" @click="addParam(param, value)">Adicionar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { MUTATION_REMOVE_SKAAR_PARAM, MUTATION_UPDATE_SKAAR_PAREMETERS } from '@/modules/accounts/graphql'

export default {
  props: {
    id: String,
    dialog: Boolean,
    parameters: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    showAddParam: false,
    param: '',
    value: ''
  }),
  methods: {
    async removeParam(key) {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_SKAAR_PARAM,
          variables: {
            id: this.id,
            key: key
          }
        })
        delete this.parameters[key]
        this.$emit('update:parameters', this.parameters)
        this.closeAddParam()
        this.$emit('close')
        this.$snackbar({ message: 'Parâmetro excluído com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao remover parâmetro', snackbarColor: '#F44336' })
      }
    },
    async addParam(param, value) {
      if (this.param.trim() === '' || this.value.trim() === '') return this.$snackbar({ message: 'Falha ao adicionar parâmetro', snackbarColor: '#F44336' })

      if (this.parameters && Object.keys(this.parameters).includes(this.param))
        return this.$snackbar({ message: 'Já existe um parâmetro com o mesmo nome', snackbarColor: '#F44336' })

      const variables = {
        id: this.id,
        parameter_name: param,
        parameter_value: value
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_SKAAR_PAREMETERS,
          variables
        })

        this.parameters[param] = value
        this.$emit('update:parameters', this.parameters)
        this.closeAddParam()
        this.$snackbar({ message: 'Parâmetro adicionado com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao adicionar parâmetro', snackbarColor: '#F44336' })
      }
    },
    closeAddParam() {
      this.showAddParam = false
      this.param = ''
      this.value = ''
    }
  }
}
</script>
